<template>
  <div>
    <FastCheckForm :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData"/>
    <vx-card>
      <div class="mt-5">
        <vs-row>
          <vs-col vs-justify="center" vs-align="center" vs-lg="2" vs-sm="3" vs-xs="6">
            <h3 class="font-bold h4">Fast Check</h3>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="9" vs-sm="7" vs-xs="6">
            <h3 class="font-bold h4">Description</h3>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="left" vs-lg="1" vs-sm="2" vs-xs="12">
            <vs-button class="mr-lg-4 mr-0 mt-sm-0 mt-4" @click="addNewData">Add</vs-button>
          </vs-col>
          <vs-divider/>
        </vs-row>

        <vs-row :key="index" v-for="(item,index) in fastChecks">
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="2" vs-sm="3" vs-xs="12">
            <p class="h5 mb-2">{{ item.name }}</p>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="9" vs-sm="7" vs-xs="12">
            <p class="h5 mb-2">
              {{ item.description }}
            </p>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="center" vs-align="left" vs-lg="1" vs-sm="2" vs-xs="12">
            <a href="#" @click.stop="showDetail(item._id)">
              <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
              />
            </a>
            <a href="#" @click.stop="editData(item)">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2"
              />
            </a>

            <a href="#" @click.stop="deleteData(item._id)">
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
              />
            </a>
          </vs-col>
          <vs-divider/>
        </vs-row>
      </div>
    </vx-card>

  </div>
</template>
<script>
import FastCheckForm from "./FastCheckForm";
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
//import moduleFastCheck from "@/store/fast-check/moduleFastCheck.js"
import {mapActions} from "vuex";


export default {
  components: {FastCheckForm},
  data() {
    return {
      path: '',
      selected: [],
      fastChecks: [],
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
    }
  },
  computed: {},
  methods: {
    ...mapActions("fastCheck", [
      "deleteFastCheck",
      "fetchFastChecks",
    ]),
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    deleteData(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.delete(id)
        }
      })

    },
    delete(id) {

      this.$vs.loading()
      this.deleteFastCheck(id)
        .then((res) => {
          this.$vs.loading.close()
          this.showMessage("Success", "Fast Check deleted successfully.", "success");
          this.fastChecks = this.$store.state.fastCheck.fastChecks;
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    showDetail(data) {
      this.$router.push({name: 'learning-center-presets-fast-check-detail', params: {id: data}});
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
  },
  created() {

    this.$vs.loading()
    this.fetchFastChecks()
      .then((res) => {
        this.$vs.loading.close()
        this.fastChecks = this.$store.state.fastCheck.fastChecks;
      })
      .catch(err => {
        this.$vs.loading.close()
        console.error(err)
      })

  }
}
</script>
